import React, { useState,useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import "./Home.css"
import Navbar from '../Components/header';
import ConfirmationPopup from '../Components/confirmation';
import axios from "axios";
import { useNavigate, Link, useHistory} from "react-router-dom";
import * as ReactRouterDOM from 'react-router-dom';
import Cookies from 'js-cookie';

function AdminHome() {
    const [Username, setUsername] = useState('')
    const [Password, setPassword] = useState('')
    const [Company_Name, setBayi] = useState('')
    const [showPopup, setShowPopup] = useState(false);
    
    const handleConfirm = () => {
      // Onaylama işlemleri

      // Popup'ı gizle
      setShowPopup(false);
    };

    const handleCancel = () => {
      // İptal işlemleri

      // Popup'ı gizle
      setShowPopup(false);
    };
    const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z';
    const headers = {
      'Authorization': '08x4IVCjdYc5ildyqBsFppOge2hNb6ms'
    };
    const navigate= useNavigate();
    useEffect(() => {
        const getToken =  () => {
          try{
            const token = Cookies.get('Admintoken');
            const decodedToken = jwtDecode(token, SECRET_KEY);
            console.log(decodedToken)
            if (!decodedToken) {
             navigate("/master/login")
            }else
            {
              navigate("/master/AdminHome")
            } 
          }
          catch(error){
            console.log(error)
            navigate("/master/login")
          }
    
        }
        getToken();
      }, []);
      const UserAdd = async () => {
        const response = await axios.post('http://185.241.138.173:9988/api/masterAddAccount', { Username, Password,Company_Name },{ headers});
        if(response.data.status){
          setShowPopup(true);
          setUsername('');
          setPassword('');
          setBayi('');
        }
      }
      
      
    
  return (
    <>
    <Navbar/>
    
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
        <h2 className="text-2xl font-bold mb-6">Kullanıcı Ekle</h2>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Kullanıcı Adı
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              onChange={(e)=>(setUsername(e.target.value))}
              type="text"
              value={Username}
              placeholder="Kullanıcı adını girin"
            />
          </div>      
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Şifre
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              name='password'
              value={Password}
              onChange={(e)=>(setPassword(e.target.value))}
              type="password"
              placeholder="Şifreyi girin"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Bayi Adı
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              value={Company_Name}
              placeholder="Bayi Adını Giriniz"
              onChange={(e)=>(setBayi(e.target.value))}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={UserAdd}
            >
              Kaydet
            </button>
          </div>
        </form>
        {showPopup && (
        <ConfirmationPopup
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      </div>
    </div>
    </>
  );
}

export default AdminHome
