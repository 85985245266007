import React, {useState, useEffect} from 'react'
import "./Login.css"
import logo from '../assets/img/ConceptCarLogo.png'
import axios from "axios";
import { useNavigate, Link, useHistory} from "react-router-dom";
import * as ReactRouterDOM from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';


const BayiLogin =() => {

    const [Username, setUsername] = useState('')
    const [token, setToken] = useState('')
    const [Password, setPassword] = useState('')
    const[girishatali, setGirishatali] = useState("")
    const navigate= useNavigate();
    const headers = {
        'Authorization': '08x4IVCjdYc5ildyqBsFppOge2hNb6ms'
      };
      
  
      const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z'
      useEffect(() => {
  
        const getToken =  () => {
          try{
            const token = Cookies.get('Usertoken');
            const decodedToken = jwtDecode(token, SECRET_KEY);
            if (!decodedToken) {
             navigate("/user/login")
            }else
            {
              navigate("/user/UserHome")
            } 
          }
          catch(error){
            console.log(error)
          }
    
        }
        getToken();
      }, []);
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('https://carrageyonetim.com/api/BayiLogin', { Username, Password },{ headers});
          const  token  = response.data.jwtToken;
          const userID = response.data.userID
          var decodedToken = jwtDecode(token, SECRET_KEY); 
          console.log(decodedToken)
          if (decodedToken) {
            console.log(userID)
            localStorage.setItem("userID", userID)
            Cookies.set('Usertoken', token);
            window.location.reload()
            navigate('/user/UserHome'); 
          } else setGirishatali("Giriş Hatalı")
        } catch (error) {
          console.error(error);
        }
      };
  

  return (
    
       <>
            <div className="sonucv2">
              <img style={{width:'200px', margin:'auto'}} src={logo}/><h1 style={{color:'black',fontSize:24,marginLeft:'10px',fontWeight:'bold'}}>DÜKKAN PANEL GİRİŞ </h1><br/>
                <form >
                    <input
                    type='text'
                    placeholder="Kullanıcı Adı"
                    onChange={(e)=>(setUsername(e.target.value))}
                    
                    />
                    <br/> <br/>
                    <input
                    type='password'
                    placeholder="Şifre"
                    onChange={(e)=>(setPassword(e.target.value))}
                    
                    />
                    <br/>  <br/>
                    <button  className="btn" style={{fontWeight:'bold', width:'100px'}} onClick={handleLogin}
                     >GİRİŞ</button>
                </form>
                
                    <br/>  <br/>
                
            </div>

            
            </>
            
        
    
  )
}
export default BayiLogin