import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import logo from './logo.svg';
import './App.css';
// Bayi
import BayiLogin from './Bayi/Login/Login';
import BayiHome from './Bayi/Home/Home';
import CreateNewShop from './Bayi/Home/CreateNewShop';
// Master
import AdminLogin from './ConceptCar/Login/Login';
import AdminHome from './ConceptCar/Home/Home';
import UserList from './ConceptCar/UserList/UserList';
import UrunList from './ConceptCar/UserList/UrunList';
import EconomyList from './ConceptCar/UserList/EconomyList';
import AracEkle from './ConceptCar/UserList/AracEkle';
import MyShops from './Bayi/Home/MyShops';
import ShopDetails from './Bayi/Home/ShopDetails'
import Tanitim from './Tanitim';
import Kvkk from './kvkk';
import Contact from './contact';
const App = () => {
  try{
    const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z'
    const token = Cookies.get('Usertoken');
    var isLoggedIn = jwtDecode(token, SECRET_KEY);
  }
  catch{
    var isLoggedIn = false;
  }
  try{
    const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z'
    const token = Cookies.get('Admintoken');
    var isLoggedInAdmin = jwtDecode(token, SECRET_KEY);
  }
  catch{
    var isLoggedInAdmin = false;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Tanitim />} />
        <Route path="/kvkk" element={<Kvkk />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/master/AdminHome" element={isLoggedInAdmin ? <AdminHome /> : <Navigate to="/master/login" replace />} />
        <Route path="/master/AdminHome/BayiList" element={isLoggedInAdmin ? <UserList /> : <Navigate to="/master/login" replace />} />
        <Route path="/master/AdminHome/AracEkle" element={isLoggedInAdmin ? <AracEkle /> : <Navigate to="/master/login" replace />} />
        <Route path="/master/AdminHome/UrunList" element={isLoggedInAdmin ? <UrunList /> : <Navigate to="/master/login" replace />} />
        <Route path="/master/AdminHome/EconomyList" element={isLoggedInAdmin ? <EconomyList /> : <Navigate to="/master/login" replace />} />
        <Route path="/user/UserHome" element={isLoggedIn ? <BayiHome /> : <BayiLogin />} />
        <Route path="/user/UserHome/createNewShop" element={isLoggedIn ? <CreateNewShop /> : <BayiLogin />} />
        <Route path="/user/UserHome/myShops" element={isLoggedIn ? <MyShops /> : <BayiLogin />} />
        <Route path="/user/UserHome/ShopDetails/:Email" element={isLoggedIn ? <ShopDetails /> : <BayiLogin />} />
        <Route path="/user/login" element={isLoggedIn ? <BayiHome /> : <BayiLogin />} />
        <Route path="/master/login" element={<AdminLogin />} />
      </Routes>
    </Router>
  );
};

export default App;
