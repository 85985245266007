import React, { useState,useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import "./userlist.css"
import Navbar from '../Components/header';
import ConfirmationPopup from '../Components/confirmation';
import axios from "axios";
import { useNavigate, Link, useHistory} from "react-router-dom";
import * as ReactRouterDOM from 'react-router-dom';
import Cookies from 'js-cookie';

function EconomyList() {
    const [Username, setUsername] = useState('')
    const [Password, setPassword] = useState('')
    const [Company_Name, setBayi] = useState('')
    const [showPopup, setShowPopup] = useState(false);
    const [users, setUsers] = useState([
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 3, name: 'Bob Johnson' }
      ]);
    
    const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z';
    const headers = {
      'Authorization': '08x4IVCjdYc5ildyqBsFppOge2hNb6ms'
    };
    const navigate= useNavigate();
    useEffect( () => {
        const UserList = async () => {
            const response = await axios.post('http://185.241.138.173:9988/api/getBayiList',Username,{headers});
            console.log(response.data)
            setUsers(response.data)
        }
        const getToken =  () => {
          try{
            const token = Cookies.get('Admintoken');
            const decodedToken = jwtDecode(token, SECRET_KEY);
            console.log(decodedToken)
            if (!decodedToken) {
             navigate("/master/login")
            }
          }
          catch(error){
            console.log(error)
            navigate("/master/login")
          }
    
        }
        UserList()
        getToken();
      }, []);
    const userDelete = async (id) => {
        console.log(id)
      }
      
      const shops = [
        {
          id: 1,
          name: 'Dükkan 1',
          details: 'Detaylar 1',
          subTable: [
            // Dükkan 1 alt tablosu
            { id: 101, name: 'Ürün 1', price: '50 TL' },
            { id: 102, name: 'Ürün 2', price: '100 TL' },
          ],
        },
        {
          id: 2,
          name: 'Dükkan 2',
          details: 'Detaylar 2',
          subTable: [
            // Dükkan 2 alt tablosu
            { id: 201, name: 'Ürün 3', price: '75 TL' },
            { id: 202, name: 'Ürün 4', price: '120 TL' },
          ],
        },
        // Daha fazla dükkan ekleyebilirsiniz
      ];

      const [activeShop, setActiveShop] = useState(null);
      const [showModal, setShowModal] = useState(false);
      const [selectedShopData, setSelectedShopData] = useState({});

      const handleDetailsClick = (shopId) => {
        setActiveShop(shopId);
        setShowModal(true);
    
        // Seçilen dükkanın alt tablosundaki ürünleri al
        const selectedShop = shops.find((shop) => shop.id === shopId);
        setSelectedShopData(selectedShop);
      };
      return (
        <>
    <Navbar/>
    <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <div className="p-6">
      <table className="table-auto border-collapse w-full">
        <thead>
          <tr>
            <th className="border px-4 py-2">ID</th>
            <th className="border px-4 py-2">Dükkan Adı</th>
            <th className="border px-4 py-2">Detaylar</th>
          </tr>
        </thead>
        <tbody>
          {shops.map((shop) => (
            <React.Fragment key={shop.id}>
              <tr className="border">
                <td className="border px-4 py-2">{shop.id}</td>
                <td className="border px-4 py-2">{shop.name}</td>
                <td className="border px-4 py-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleDetailsClick(shop.id)}
                  >
                    Detaylar
                  </button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-lg font-bold mb-4">Dükkan Detayları</h2>
            <table className="table-auto border-collapse w-full">
              <thead>
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Ürün Adı</th>
                  <th className="border px-4 py-2">Fiyat</th>
                </tr>
              </thead>
              <tbody>
                {selectedShopData.subTable.map((product) => (
                  <tr key={product.id} className="border">
                    <td className="border px-4 py-2">{product.id}</td>
                    <td className="border px-4 py-2">{product.name}</td>
                    <td className="border px-4 py-2">{product.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowModal(false)}
            >
              Kapat
            </button>
          </div>
        </div>
      )}
    </div>
        </>
      );
}

export default EconomyList
