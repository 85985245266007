import React, { useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import { useNavigate, Link, useHistory } from "react-router-dom";
import * as ReactRouterDOM from 'react-router-dom';
import Cookies from 'js-cookie';
import Navbar from '../components/Navbar';
import CreateNewShop from './CreateNewShop';
import axios from 'axios';
import Footer from '../components/Footer';
import logo from '../assets/img/logo.png'


function BayiHome() {
  const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z'
  const navigate = useNavigate();
  useEffect(() => {

    const getToken = () => {
      try {
        const token = Cookies.get('Usertoken');
        const decodedToken = jwtDecode(token, SECRET_KEY);
        console.log(decodedToken)
        if (!decodedToken) {
          navigate("/user/login")
        } else {
          navigate("/user/UserHome")
          
        }
      }
      catch (error) {
        console.log(error)
        navigate("/user/login")
      }

    }
    getToken();
  }, []);

  const logout = () => {
    Cookies.remove('Usertoken');
    navigate("/user/login")
  }

  const goToBayiOlustur = () => {
      navigate('/user/UserHome/createNewShop')
  }
  const goToBayilerim = () => {
    navigate('/user/UserHome/myShops')
  }


  return (
    <>
      <Navbar />
      <br /><br /><br /><br /><br /><br /><br />
      <div className='main'>
        <div style={{fontSize:36, fontWeight:'bold',marginBottom:10, width:'100%'}}>CARRAGE BAYİ PANELİ</div>
        <div style={{fontSize:24, fontWeight:'bold',marginBottom:50,width:'100%'}}>Hoşgeldiniz</div>
        <img src={logo} style={{margin:'auto', width:'15%', height:'15%', marginBottom:'5%'}}/>

        <div className='bilesenler'>
          <div onClick={goToBayiOlustur} className='bilesen-bayiOlustur'>
            BAYİ OLUŞTUR
          </div>
          <div  onClick={goToBayilerim}  className='bilesen-bayilerim'>
            BAYİLERİM
          </div>

        </div>
      </div>
      
      <br /><br /><br /><br /><br /><br /><br />
      <Footer/>
      
    </>
  )
}

export default BayiHome
