import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useParams, useNavigate,useLocation  } from "react-router-dom";
import Cookies from 'js-cookie';
import './Bayi.css';
import Navbar from '../components/Navbar';
import axios from 'axios';
import Footer from '../components/Footer';
import IcYikamaPhoto from '../assets/img/icyikama.PNG';
import DisYikamaPhoto from '../assets/img/disyikama.PNG';
import IcDisYikamaPhoto from '../assets/img/icdisyikama.PNG';
const ShopDetails = () => {
  const navigate = useNavigate();
  const [shop, setShop] = useState({});
  const { Email } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const table = searchParams.get('table');
  const shopID = searchParams.get('shopID');
  const [bayiTable,setbayiTable] = useState(table)
  const [bayishopID,setshopID] = useState(shopID)
  const [dukkanMesaj, setDukkanMesaj] = useState("");
  const [products, setProducts] = useState([]);
  const [productNameVarsayilan, setProductNameVarsayilan] = useState(1);
  const [productPriceVarsayilan, setProductPriceVarsayilan] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalVarsayilan, setShowModalVarsayilan] = useState(false);
  const [productContent, setProductContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [EditedSelectedFile, setEditedSelectedFile] = useState(null);
  const [productPrice, setProductPrice] = useState('');
  const [productCategory, setProductCategory] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [EditProductContent, setEditProductContent] = useState('');
  const [EditProductPrice, setEditProductPrice] = useState('');
  const [EditProductPhoto, setEditProductPhoto] = useState('');
  const [EditProductCategory, setEditProductCategory] = useState(null);
  const [productIndex, setProductIndex] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [orders, setOrders] = useState([]);
  const [selectedDukkanImage, setSelectedDukkanImage] = useState(null);
  const [showDukkanImgModal, setShowDukkanImgModal] = useState(false);
  const [showOrderHistoryModal, setShowOrderHistoryModal] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [dukkanPhoto, setDukkanPhoto] = useState('');
  const [photos, setPhotos] = useState([]);

  useEffect(() => {

    const fetchShopDetails = async () => {
      try {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
  
        const token = Cookies.get('Usertoken');
        const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z';
        const decodedToken = jwtDecode(token, SECRET_KEY);
        console.log(decodedToken);
  
        if (!decodedToken) {
          navigate("/user/login");
        } else {
          const headers = {
            Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
          };
          const body = {
            UserId: bayishopID,
            Table: bayiTable,
          };

          console.log(body)
          const response = await axios.post('https://carrageyonetim.com/api/getDukkanInfo', body, { headers });
          
          if (response) {
            const shopData = response.data;
            setShop(shopData);
            console.log(shopData)
            if (shopData.isOpen) {
              setDukkanMesaj("DÜKKAN AÇIK");
              setShop(prevShop => ({ ...prevShop, status: true }));
            } else {
              setDukkanMesaj("DÜKKAN KAPALI");
              setShop(prevShop => ({ ...prevShop, status: false }));
            }
            setDukkanPhoto(shopData.DukkanPhoto)
            console.log(shopData);
            fetchProducts(shopData);
          }
        }
      } catch (error) {
        console.log(error);
        navigate("/user/login");
      }
    };
  
    const fetchProducts = async (shopData) => {
      try {
        const headers = {
          Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
        };
        const body = {
          UserId: shopID,
          Table: table,
        };
        console.log(body);
  
        const response = await axios.post('https://carrageyonetim.com/api/bayiProductList', body, { headers });
        console.log(body);
        console.log(response.data);
  
        if (response.data.status === false) {
          console.log(response.data.errMessage);
        } else {
          const productsArray = Array.isArray(response.data) ? response.data : [];
          setProducts(productsArray);
          getDukkanOrder(shopData);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const getDukkanOrder = async (shopData) => {
      try {
        const headers = {
          Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
        };
        const body = {
          userID: shopID,
          Table: table,
        };
  
        const response = await axios.post('https://carrageyonetim.com/api/getDukkanOrderList', body, { headers });
  
        if (response.data.status === true) {
          const reversedOrders = response.data.orderList.reverse();
          setOrders(reversedOrders);
          console.log(reversedOrders);
          getDukkanPhoto(shopData);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const getDukkanPhoto = async (shopData) => {
      try {
        const headers = {
          Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
        };
        const body = {
          UserId: shopData.Id,
          Table: shopData.Table,
        };
        console.log(body);
  
        const response = await axios.post('https://carrageyonetim.com/api/getDukkanInfo', body, { headers });
  
        console.log(response.data.DukkanPhoto);
        setDukkanPhoto(response.data.DukkanPhoto)
        
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchShopDetails();
  }, [Email, navigate]);
  


  const Open = async () => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };
      const body = {
        UserID: localStorage.getItem("userID"),
        id: shop.Id,
        Table: shop.Table,
        status: true
      }
      console.log(body)
      const response = await axios.post('https://carrageyonetim.com/api/CloseOpenBayi', body, { headers });

      if (response) {
        console.log(response.data)
        setDukkanMesaj("DÜKKAN AÇIK")
        setShop(prevShop => ({ ...prevShop, status: true }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const Close = async () => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };
      const body = {
        UserID: localStorage.getItem("userID"),
        id: shop.Id,
        Table: shop.Table,
        status: false
      }
      console.log(body)

      const response = await axios.post('https://carrageyonetim.com/api/CloseOpenBayi', body, { headers });

      if (response) {
        console.log(response.data)
        setDukkanMesaj("DÜKKAN KAPALI")
        setShop(prevShop => ({ ...prevShop, status: false }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const Delete = async () => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };
      const body = {
        BayiId: localStorage.getItem("userID"),
        DukkanId: shop.Id,
        Table: shop.Table,

      }
      console.log(body)

      const response = await axios.post('https://carrageyonetim.com/api/deleteShops', body, { headers });

      if (response) {
        console.log(response.data)
        setDukkanMesaj("DÜKKAN SİLİNDİ")
        window.location.reload()

      }
    } catch (error) {
      console.log(error);
    }
  }
  const ResetHours = async () => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };
      const body = {
        BayiId: localStorage.getItem("userID"),
        DukkanId: shop.Id,
        Table: shop.Table,

      }
      console.log(body)

      const response = await axios.post('https://carrageyonetim.com/api/resetHours', body, { headers });

      if (response) {
        console.log(response.data)
        setDukkanMesaj("Saatler Sıfırlandı")
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalOpenVarsayilan = () => {
    setShowModalVarsayilan(true);
  };
  const handleEditModalOpen = (index) => {
    const product = products[index];
    setEditProductContent(product.Product_Content);
    setEditProductPhoto(product.Product_Photo);
    setEditProductPrice(product.Product_Price);
    setProductIndex(index);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    // İnput alanlarını sıfırla
    setEditProductContent('');
    setEditProductPhoto('');
    setEditProductPrice('');
  }

  const handleModalClose = () => {
    setShowModal(false);
    // İnput alanlarını sıfırla
    setProductContent('');
    setProductPrice('');
  };
  const handleModalCloseVarsayilan = () => {
    setShowModalVarsayilan(false);
    // İnput alanlarını sıfırla
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleEditFileChange = (e) => {
    const file = e.target.files[0];
    setEditedSelectedFile(file);
  };
  const handleProductAdd = async () => {

    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };

      const formData = new FormData();
      formData.append('UserId', bayishopID);
      formData.append('varsayilan', false);
      formData.append('Table', table);
      formData.append('Content', productContent);
      formData.append('Product_Image', selectedFile);
      formData.append('Product_Price', productPrice);
      formData.append('Product_Category', productCategory ? productCategory : 1);
      formData.append('isOpenProduct', true);

      console.log(formData);

      const response = await axios.post('https://carrageyonetim.com/api/addProductBayi', formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });


      // İşlem başarılıysa
      console.log('Ürün eklendi:', response.data);
      window.location.reload();

      // Modalı kapat ve input alanlarını sıfırla
      handleModalClose();
    } catch (error) {
      // Hata durumunda
      console.error('Ürün ekleme hatası:', error);
    }
  };
  const handlePhotoUpload = (event) => {
    const uploadedPhotos = Array.from(event.target.files);
    setPhotos((prevPhotos) => [...prevPhotos, ...uploadedPhotos]);
  };

  const handleSubmit = async () => {
    // Fotoğrafları sunucuya göndermek için burada gerekli işlemleri yapın
    console.log('Gönderilen fotoğraflar:', photos);
    const headers = {
      Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
    };
    let formDataEdit;
    let contentType;
    formDataEdit = new FormData();
    photos.forEach((photo, index) => {
      formDataEdit.append('Product_Image', photo);
    });
    formDataEdit.append('orderID', selectedOrderID);
    contentType = 'multipart/form-data';
    // Ürün Düzenleme işlemleri
    console.log(photos)
    const response = await axios.post('https://carrageyonetim.com/api/addOrderVehiclePhotos', formDataEdit, {
      headers: {
        ...headers,
        'Content-Type': contentType,
      },
    });
    if(response.data.status){
      window.location.reload();
    }
    else{
      alert("Bir Sorun Oluştu")
    }
    

  };
  const handleProductAddVarsayilan = async () => {

    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };
      const formData = new FormData();
      const photoPath = '../assets/img/icyikama.PNG';
      const file = await fetch(photoPath)
            .then(response => response.blob())
            .then(blob => new File([blob], 'icyikama.PNG', { type: 'image/png' }));
      formData.append('UserId', bayishopID);
      formData.append('Table', table);
      switch (productNameVarsayilan) {
        case 1:
          formData.append('Content', "İç Yıkama");
          formData.append('Product_Image_Url', "icyikama.PNG");
          break;
        case 2:
          formData.append('Content', "Dış Yıkama");
          formData.append('Product_Image_Url', "disyikama.PNG");
          break;
        case 3:
          formData.append('Content', "İç Dış Yıkama");   
          formData.append('Product_Image_Url', "icdisyikama.PNG");    
          break;
      }
      formData.append('varsayilan', true);
      formData.append('Product_Image', file);
      formData.append('Product_Price', productPriceVarsayilan);
      formData.append('Product_Category', productCategory ? productCategory : 1);
      formData.append('isOpenProduct', true);

      console.log(formData);

      const response = await axios.post('https://carrageyonetim.com/api/addProductBayi', formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });


      // İşlem başarılıysa
      console.log('Ürün eklendi:', response.data);
      window.location.reload();

      // Modalı kapat ve input alanlarını sıfırla
      handleModalClose();
    } catch (error) {
      // Hata durumunda
      console.error('Ürün ekleme hatası:', error);
    }
  };
  const handleProductEdit = async () => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };
      
      const editedProduct = products[productIndex]; // Düzenlenen ürünü al
      
      let formDataEdit;
      let contentType;
      
      // Eğer EditedSelectedFile varsa Product_Photo anahtarıyla ekleyin
      if (EditedSelectedFile) {
        formDataEdit = new FormData();
        formDataEdit.append('UserId', shop.Id);
        formDataEdit.append('Table', bayiTable);
        formDataEdit.append('Content', EditProductContent);
        formDataEdit.append('Product_ID', editedProduct.Product_ID);
        formDataEdit.append('Product_Price', EditProductPrice);
        formDataEdit.append('Product_Photo', EditedSelectedFile);
        formDataEdit.append('Product_Category', Number(EditProductCategory) ? EditProductCategory : 1);
        contentType = 'multipart/form-data';
      } else {
        formDataEdit = {
          UserId: shop.Id,
          Table: bayiTable,
          Content: EditProductContent,
          Product_ID: editedProduct.Product_ID,
          Product_Price: EditProductPrice,
          Product_Photo: editedProduct.Product_Photo,
          Product_Category: Number(EditProductCategory) ? EditProductCategory : 1
        };
        contentType = 'application/x-www-form-urlencoded';
      }
      
      // Ürün Düzenleme işlemleri
      const response = await axios.post('https://carrageyonetim.com/api/editProductBayi', formDataEdit, {
        headers: {
          ...headers,
          'Content-Type': contentType,
        },
      });
      
      

      console.log('Ürün Düzenlendi:', response.data);
      window.location.reload();
      // Modalı kapat ve input alanlarını sıfırla
      handleEditModalClose();
    } catch (error) {
      // Hata durumunda
      console.error('Ürün düzenleme hatası:', error);
    }
  };




  const handleDeleteProduct = async (productId) => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };
      const body = {
        UserId: shop.Id,
        Table: table,
        Product_ID: productId
      }

      const response = await axios.post('https://carrageyonetim.com/api/removeProductBayi', body, { headers });
      console.log(body)

      console.log('Ürün Silindi:', response.data);
      window.location.reload();

    } catch (error) {
      console.error('Ürün silme hatası:', error);
    }
  };

  const orderStatusOptions = [
    { value: '1', label: 'Sipariş Alındı' },
    { value: '2', label: 'Kurye Yolda' },
    { value: '3', label: 'Sipariş Tamamlandı' },
  ];
  const [selectedOrderID, setSelectedOrderID] = useState('');
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddPhotoModal, setshowAddPhotoModal] = useState(false);
  // Sipariş durumu güncelleme işlemleri
  const handleOrderStatusUpdate = (orderID) => {
    // Seçilen siparişin ID'sini ve durumunu sakla
    setSelectedOrderID(orderID);
    setSelectedOrderStatus('');

    // Modalı göster
    setShowUpdateModal(true);
  };
  const handleOrderCarPhotos = (orderID) => {
    // Seçilen siparişin ID'sini ve durumunu sakla
    setSelectedOrderID(orderID);
    setSelectedOrderStatus('');

    // Modalı göster
    setshowAddPhotoModal(true);
  };
  const handleOrderStatusCancel = async (orderID) => {
    // Seçilen siparişin ID'sini ve durumunu sakla
    const headers = {
      Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
    };

    const body = {
      orderID: orderID
    };
    
    const response = await axios.post('https://carrageyonetim.com/api/cancelOrder', body, { headers });
    console.log(response.data);
    window.location.reload();
  };
  const handleOrderStatusSubmit = async () => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };

      const body = {

        value: selectedOrderStatus,
        orderID: selectedOrderID
      };


      const response = await axios.post('https://carrageyonetim.com/api/changeDukkanOrder', body, { headers });
      console.log(response.data);
      window.location.reload();
      // İşlem tamamlandığında modalı kapat
      setShowUpdateModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <>
        <Navbar />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          fontSize: 28,
          fontWeight: 'bold',
        }}>Yükleniyor...</div>

        <Footer />
      </>
    );
  }
  const filteredProducts = products.filter((product) => {
    return product.Product_Content.toLowerCase().includes(searchTerm.toLowerCase());
  });
  function getProductCategoryName(category) {
    switch (category) {
      case 1:
        return "Ana Kategori";
      case 2:
        return "Yan Kategori";
      case 3:
        return "Soft Kategori";
      default:
        return "Bilinmeyen Kategori";
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedDukkanImage(file);
  };

  const handleDukkanImageModalOpen = () => {
    setShowDukkanImgModal(true);
  };

  const handleDukkanImageModalClose = () => {
    setShowDukkanImgModal(false);
    setSelectedDukkanImage(null);
  };
     
 

  const addDukkanPhoto = async (shop) => {
    try {
      const headers = {
        Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
      };

      const formData = new FormData();
      formData.append('userID', shop.Id);
      formData.append('Table', table);
      formData.append('Dukkan_Image', selectedDukkanImage);

      console.log(selectedDukkanImage)
      console.log(formData);

      const response = await axios.post('https://carrageyonetim.com/api/addPhotoDukkan', formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });


      // İşlem başarılıysa
      console.log('Foto eklendi:', response.data);
      
      window.location.reload();

      // Modalı kapat ve input alanlarını sıfırla
      handleModalClose();
    } catch (error) {
      // Hata durumunda
      console.error('Ürün ekleme hatası:', error);
    }
  };

  const handleOrderHistoryModalOpen = (shop) => {
    const headers = {
      Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
    };
    const body = {
      userID: shop.Id,
      Table: table,
    };
    axios
      .post('https://carrageyonetim.com/api/getDukkanComplatedOrderList', body, { headers })
      .then((response) => {
        console.log(response.data);
        const orderListWithDegerlendirmeKontrol = response.data.orderList.map((order) => ({
          ...order,
          degerlendirmeKontrol: order.orderDukkanSurvey === true,
        }));
        setOrderHistory(orderListWithDegerlendirmeKontrol);
        setShowOrderHistoryModal(true);
      })
      .catch((error) => {
        console.error('Error fetching order history:', error);
      });
  };
  
  const handleRatingSubmit = () => {
    const headers = {
      Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
    };
    const body = {
      orderID: selectedOrderID,
      Rating: rating,
      Content: comment,
    };
    console.log(body);
    axios
      .post('https://carrageyonetim.com/api/surveyDukkan', body, { headers })
      .then((response) => {
        console.log(response.data);
        setShowRatingModal(false);
        if (response.data.status === true) {
          // Değerlendirme işlemi başarılı olduğunda sadece ilgili müşterinin degerlendirmeKontrol durumunu güncelle
          setOrderHistory((prevOrderHistory) =>
            prevOrderHistory.map((order) =>
              order.OrderID === selectedOrderID
                ? { ...order, degerlendirmeKontrol: true }
                : order
            )
          );
          setRating(0);
          setComment('');
        }
      })
      .catch((error) => {
        console.error('Error fetching order history:', error);
      });
  };
  

  const handleOrderHistoryModalClose = () => {
    // Implement a function to close the order history modal
    setShowOrderHistoryModal(false);
    setSelectedOrderID(null); // Reset the selectedOrderID when closing the modal
  };

  const handleRatingModalOpen = (orderId) => {
    // Implement a function to set the state of the rating modal and store the selected order ID
    setSelectedOrderID(orderId);
    setShowRatingModal(true);
    // Additional logic to handle specific order for rating can be added here
  };

  const handleRatingModalClose = () => {
    // Implement a function to close the rating modal
    setShowRatingModal(false);
  };
  return (
    <>
      <Navbar />
      <br /><br /><br /><br /><br /><br />
      <div className='mainShopdetails'>
        <hr></hr>
        <div className='dukkandetay'>
          <div style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', fontSize: 24, marginBottom: '2%' }}>
            <u>BAYİ DETAYLARIM</u>
          </div>
          <button className='btn-ddb' onClick={handleDukkanImageModalOpen} > + DÜKKAN FOTOSU EKLE</button>
          <br />
          {/* Fotoğraf ekleme modalı */}
          {showDukkanImgModal && (
            <div className="modal">
              <div className="modal-content">
                <h2>Fotoğraf Ekle</h2>
                <form>
                  <input
                    className="urunEkleme-input"
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                  <br />
                  <button type="button" onClick={() => addDukkanPhoto(shop)}>
                    EKLE
                  </button>
                  <br />
                  <button type="button" onClick={handleDukkanImageModalClose}>
                    Kapat
                  </button>
                </form>
              </div>
            </div>
          )}

    <img style={{ width: 500, height: 250, marginBottom:50 }} src={`https://carrageyonetim.com/uploads/images/${dukkanPhoto}`} alt="Dükkan Fotoğrafı" />

          <p style={{ fontSize: 18, fontWeight:'bold' }}><b style={{color:'gray'}}>Dükkan Adı:</b> {shop.Name}</p>
          <p style={{ fontSize: 18, fontWeight:'bold'  }}><b style={{color:'gray'}}>İl: </b>{shop.il}</p>
          <p style={{ fontSize: 18, fontWeight:'bold'   }}><b style={{color:'gray'}}>İlçe: </b>{shop.ilce}</p>
          <p style={{ fontSize: 18 , fontWeight:'bold' }}><b style={{color:'gray'}}>Adres: </b> {shop.adres}</p>
          <p style={{ fontSize: 18 , fontWeight:'bold' }}><b style={{color:'gray'}}>Dükkan Sahibi: </b> {shop.dukkanSahibi}</p>
          <p style={{ fontSize: 18 , fontWeight:'bold' }}><b style={{color:'gray'}}>Verdiği Hizmet: </b> {bayiTable}</p>
          {/* <p style={{ fontSize: 18 }}> <b>Dükkan ID: </b> {shop.Id}</p> */}
          <p style={{ fontSize: 18 }}> <b style={{color:'gray'}}>Durum: </b> <b><i style={{ color: shop.status ? 'green' : 'red' }}>{dukkanMesaj}</i></b></p>



          <br />
          <div className='dukkan-detay-butonlar'>
            <button className='btn-ddb' onClick={Open}>DÜKKANI AÇ</button>
            <br />
            <button className='btn-ddb' onClick={Close}>DÜKKANI KAPAT</button>
            <br />


          </div>
          <button className='btn-ddb' onClick={Delete}>DÜKKANI SİL</button>
          <button className='btn-ddb' onClick={ResetHours}>DOLU SAATLERİ SIFIRLA</button>
          <hr></hr>
          



        </div>




        <hr></hr>



        <div className='bayi-urunEkleme'>
          <div style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', marginBottom: '2%' }}>
            <u style={{ fontSize: 24 }}>BAYİ ÜRÜNLERİM</u>
            <div className='bayi-urunEkleme-butonlar'>
              <input
                className='urunArama-input'
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Ürün Adı Ara"
              />
              <button className='bayi-urunEklemeButonu' style={{ fontSize: 'auto'}} onClick={handleModalOpenVarsayilan}>+ VARSAYILAN ÜRÜN EKLE</button>
              <button className='bayi-urunEklemeButonu' style={{ fontSize: 'auto'}} onClick={handleModalOpen}>+ ÖZEL ÜRÜN EKLE</button>
            </div>
          </div>
          {filteredProducts.length === 0 ? (
            <p style={{ textAlign: 'center', fontSize: 18 }}><i>Ürün bulunmamaktadır.</i></p>
          ) : (
            <ul className='product-list'>


              {filteredProducts.map((product, index) => (
                <li key={index} className='product-item'>
                  {/* Ürün bilgileri */}
                  <img style={{ width: "20%", height: "40" }} src={`https://carrageyonetim.com/uploads/images/${product.Product_Photo}`} alt="Ürün Fotoğrafı" />

                  <p style={{ fontWeight:'bold'}}><b>Ürün Adı:</b> {product.Product_Content}</p>
                  <p  style={{ fontWeight:'bold'}} ><b>Fiyat:</b> {product.Product_Price} TL</p>
                  <p  style={{ fontWeight:'bold'}}><b>Aktiflik Durumu:</b> {!(product.isOpenProduct) ? "Aktif" : "Aktif Değil"}</p>
                  <p  style={{ fontWeight:'bold'}}><b>Ürün Kategorisi:</b> {getProductCategoryName(product.Product_Category)}</p>




                  {/* Düzenleme ve silme butonları */}
                  <div className="product-buttons" style={{ textAlign: 'right' }}>
                    <button className='bayi-urunDüzenleButonu' onClick={() => handleEditModalOpen(index)} style={{ marginRight: '5px' }}>Düzenle</button>
                    <button className='bayi-urunDüzenleButonu' onClick={() => handleDeleteProduct(product.Product_ID)}>Sil</button>
                  </div>
                </li>
              ))}

            </ul>
          )}
        </div>

        

        {/* Ürün ekleme modalı */}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', fontSize: 35, marginTop: '4%', marginBottom: '2%' }}>Ürün Ekle</h2>
              <div className='bayi-urunEkleme-butonlar'>
                <input
                  className='urunEkleme-input'
                  type="text"
                  value={productContent}
                  onChange={(e) => setProductContent(e.target.value)}
                  placeholder="Ürün İçeriği"
                />
                <input
                  className='urunEkleme-input'
                  type="number"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  placeholder="Fiyat (TL)"
                />
                <input
                  className="urunEkleme-input"
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                />
                <select
                  className='urunEkleme-input'
                  value={productCategory || ''}
                  onChange={(e) => setProductCategory(parseInt(e.target.value))}
                >
                  <option value="1">Ana Hizmet</option>
                  <option value="2">Yan Hizmet</option>
                  <option value="3">Ek Ürün</option>
                </select>

              </div>
              <div className='bayi-urunEkleme-butonlar' >
                <button className='bayi-urunKaydetmeButonu' onClick={handleProductAdd}>Kaydet</button>
                <button className='bayi-urunKaydetmeButonu' onClick={handleModalClose}>İptal</button>
              </div>
            </div>
          </div>
        )}
        {showModalVarsayilan && (
          <div className="modal">
            <div className="modal-content">
              <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', fontSize: 35, marginTop: '4%', marginBottom: '2%' }}>Varsayılan Ürün Ekle</h2>
              <div className='bayi-urunEkleme-butonlar'>
                <select
                    className='urunEkleme-input'
                    value={productNameVarsayilan || ''}
                    onChange={(e) => setProductNameVarsayilan(parseInt(e.target.value))}
                  >
                  <option value="1">İÇ YIKAMA</option>
                  <option value="2">DIŞ YIKAMA</option>
                  <option value="3">İÇ DIŞ YIKAMA</option>
                </select>
                <input
                  className='urunEkleme-input'
                  type="number"
                  value={productPriceVarsayilan}
                  onChange={(e) => setProductPriceVarsayilan(e.target.value)}
                  placeholder="Fiyat (TL)"
                />
              </div>
              <div className='bayi-urunEkleme-butonlar' >
                <button className='bayi-urunKaydetmeButonu' onClick={handleProductAddVarsayilan}>Kaydet</button>
                <button className='bayi-urunKaydetmeButonu' onClick={handleModalCloseVarsayilan}>İptal</button>
              </div>
            </div>
          </div>
        )}
        {/* Ürün Düzenleme modalı */}
        {showEditModal && (
          <div className="modal">
          <div className="modal-content">
            <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', fontSize: 35, marginTop: '4%', marginBottom: '2%' }}>Ürün Düzenle</h2>
            <div className='bayi-urunEkleme-butonlar'>
              <input
                className='urunEkleme-input'
                type="text"
                value={EditProductContent}
                onChange={(e) => setEditProductContent(e.target.value)}
                placeholder="Ürün İçeriği"
              />
            </div>
            <div className='bayi-urunEkleme-butonlar'>
              <input
                className='urunEkleme-input'
                type="number"
                value={EditProductPrice}
                onChange={(e) => setEditProductPrice(e.target.value)}
                placeholder="Fiyat (TL)"
              />
            </div>
            <div className='bayi-urunEkleme-butonlar'>
              <input
                className="urunEkleme-input"
                type="file"
                onChange={handleEditFileChange}
                accept="image/*"
              />
            </div>
            <div className='bayi-urunEkleme-butonlar'>
              <select
                className='urunEkleme-input'
                value={EditProductCategory || ''}
                onChange={(e) => setEditProductCategory(parseInt(e.target.value))}
              >
                <option value="1">Ana Kategori</option>
                <option value="2">Yan Kategori</option>
                <option value="3">Ek Kategori</option>
              </select>
            </div>
            <div className='bayi-urunEkleme-butonlar'>
              <button className='bayi-urunKaydetmeButonu' onClick={handleProductEdit}>Kaydet</button>
              <button className='bayi-urunKaydetmeButonu' onClick={handleEditModalClose}>İptal</button>
            </div>
          </div>
        </div>
        
        )}
        <hr></hr>
        <br /><br /><br /><br />
        
        <Footer />
      </div>
      <div className="siparislerimContainer">
        <button className='siparis-gecmisi-button' onClick={() => handleOrderHistoryModalOpen(shop)}>
          SİPARİŞ GEÇMİŞİ
        </button>
      </div>

      {
  showOrderHistoryModal && (
    <div className="modal">
      <div className="modal-content">
        <b>
          <h1>Sipariş Geçmişi</h1>
        </b>
        <div className="modal-scrollable-content">
          <ul>
            {orderHistory.map((order) => (
              <li key={order.OrderID}>
                <p>Adres: {order.OrderGivenAddress}</p>
                <p>Plaka: {order.OrderGivenPlate}</p>
                <p>Sipariş Tarihi: {order.OrderDate}</p>
                <p>Toplam Fiyat: {order.TotalPrice} TL</p>

                {order.degerlendirmeKontrol ? (
                  <b><p> Değerlendirilmiş</p></b>
                ) : (
                  <button onClick={() => handleRatingModalOpen(order.OrderID)}>
                    Müşteriyi Değerlendir
                  </button>
                  
                )}
<hr></hr>
                {showRatingModal && selectedOrderID === order.OrderID && (
                  <div className="rating-modal">
                    <div className="rating-container">
                      <p>Puan: {rating}</p>
                      <div className="rating-box">
                        {[1, 2, 3, 4, 5].map((value) => (
                          <label key={value}>
                            <input
                              type="radio"
                              value={value}
                              checked={rating === value}
                              onChange={() => setRating(value)}
                            />
                            <span className={`rating-star ${rating >= value ? 'selected' : ''}`}>
                              {value}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                    <label>
                      Yorum:
                      <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
                    </label>
                    <button onClick={handleRatingSubmit}>Değerlendir</button>
                    <button onClick={handleRatingModalClose}>İptal</button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <button onClick={handleOrderHistoryModalClose}>Kapat</button>
      </div>
    </div>
  )
}


            <div  style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', fontSize: 24, marginTop: '4%',marginBottom: '4%' }}>SİPARİŞLERİM
         
         <div className='siparislerim'>
            {orders.length === 0 ? (
              <p style={{ textAlign: 'center', fontSize: 18 }}>Sipariş Bulunmamaktadır.</p>
            ) : (
              <>
                <ul>
                  {orders.map((order) => (
                    <>
                    <li style={{ textAlign: 'center' }} key={order.OrderID}>
                      <div>ADRES: <b>{order.OrderGivenAddress.toUpperCase()}</b></div>
                      <div>ARAÇ PLAKASI: <b>{order.OrderGivenPlate}</b></div>
                      {order.orderContent ? (
                        
                        <div>
                        İÇERİK:
                        <b>
                          {JSON.parse(order.orderContent).map((element, index) => (
                            <span key={index}>
                              {element.Product_Name.toUpperCase()}
                              {index !== order.orderContent.length - 1 ? ", " : ""}
                            </span>
                          ))}
                        </b>
                      </div>

                      ) : (
                        <div>İçerik yok</div>
                      )}
                      <div>ÖDEME YÖNTEMİ: <b>{order.PayoutType === "1" ? "Nakit Ödeme" : order.PayoutType === "2" ? "Kapıda Kredi Kartı" : "Online Ödeme"}</b></div>
                      <div>TARİH: <b>{(new Date(Date.parse(order.OrderDate))).toLocaleDateString('tr-TR')} {(new Date(order.orderHours)).toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) } </b></div>
                      <div>RANDEVU SAATİ: <b>{order.OrderSelectedTime}</b></div>
                      <div>MÜŞTERİ İSMİ: <b>{order.OrderGivenName}</b></div>
                      <div>MÜŞTERİ TEL NO: <b>{order.OrderGivenPhoneNumber}</b></div>
                      <div>TOPLAM FİYAT: <b>{order.TotalPrice} TL</b></div>
                      {order.VehiclePhotos && (
                      <div>
                        ARAÇ FOTOĞRAFLARI:
                        <div className="photo-preview" style={{justifyContent:"center",display: "flex"}}>
                          {order.VehiclePhotos.split(',').map((photo, index) => (
                            
                            <img 
                              key={index} 
                              src={"https://carrageyonetim.com/uploads/images/" + photo.trim()}  // trim() ile gereksiz boşlukları kaldırıyoruz
                              alt={`uploaded ${index}`} 
                              className="photo-thumbnail" 
                            />
                            
                          ))}
                        </div>
                      </div>
                    )}
                      <div>Sipariş Durumu:
              <b style={{
              color: order.OrderStatus === 3 ? 'darkgreen' :
                    order.OrderStatus === 2 ? 'green' :
                    order.OrderStatus === 1 ? 'blue' :
                    'red'
          }}>
              {order.OrderStatus === 3 ? "Sipariş Tamamlandı" :
              order.OrderStatus === 2 ? "Kurye Yolda" :
              order.OrderStatus === 1 ? "Sipariş Alındı" :
              "Sipariş İptal Edildi"}
          </b>

</div>

                      <button className='update-button' onClick={() => handleOrderCarPhotos(order.OrderID)}>Araç Fotoğrafları Ekle</button>
                      <button className='update-button' onClick={() => handleOrderStatusUpdate(order.OrderID)}>Sipariş Durum Güncelle</button>
                      <button className='update-button' style={{color:'red'}} onClick={() => handleOrderStatusCancel(order.OrderID)}>Siparişi İptal Et</button>
                    </li>
                    <hr></hr>
                    </>
                  ))}
                  
                </ul>


                {/* Sipariş durumu güncelleme modalı */}
                {showUpdateModal && (
                  <div className="modal">
                    <div className="modal-content">
                      <h2>SİPARİŞ DURUMUNU GÜNCELLE</h2>
                      <form>
                        <div>
                          {orderStatusOptions.map((option) => (
                            <>
                              <label key={option.value}>
                                <input
                                  type="radio"
                                  name="orderStatus"
                                  value={option.value}
                                  checked={selectedOrderStatus === option.value}
                                  onChange={(e) => setSelectedOrderStatus(e.target.value)}
                                  className="radio-input"
                                />
                                {option.label}
                              </label>
                              <hr></hr>
                            </>
                          ))}
                        </div>
                        <div>
                          <button type="button" onClick={handleOrderStatusSubmit}>Güncelle</button>
                          <button type="button" onClick={() => setShowUpdateModal(false)}>İptal</button>
                        </div>
                      </form>
                      <p className="modal-warning">Uyarı:<br /> Sipariş durumu güncellendikten sonra <br />işlemin geri alınamayacağını unutmayın!<br />
                     

                      </p>
                    </div>
                  </div>
                )}
                {showAddPhotoModal && (
                  <div className="modal">
                  <div className="modal-content">
                    <h2>Fotoğraf Yükle</h2>
                    <div className="photo-upload">
                      <input 
                        type="file" 
                        accept="image/*" 
                        multiple 
                        onChange={handlePhotoUpload} 
                      />
                      <div className="photo-preview">
                        {photos.map((photo, index) => (
                          <img 
                            key={index} 
                            src={URL.createObjectURL(photo)} 
                            alt={`uploaded ${index}`} 
                            className="photo-thumbnail" 
                          />
                        ))}
                      </div>
                    </div>
                    <button type="button" onClick={handleSubmit}>Gönder</button>
                  </div>
                </div>
                )}
              </>
            )}
            </div>
          </div>
    </>
  );

}

export default ShopDetails;
