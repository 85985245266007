import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate,Link } from "react-router-dom";
import Cookies from 'js-cookie';
import './Bayi.css';
import Navbar from '../components/Navbar';
import axios from 'axios';
import Footer from '../components/Footer';

const MyShops = () => {
  const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z';
  const navigate = useNavigate();
  const [shops, setShops] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 1500);
    const getToken = async () => {
      try {
        const token = Cookies.get('Usertoken');
        const decodedToken = jwtDecode(token, SECRET_KEY);
        console.log(decodedToken);
        if (!decodedToken) {
          navigate("/user/login");
        } else {
          

          const headers = {
            Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
          };
          const body = {
            UserId: localStorage.getItem("userID"),
          }
           console.log(body)
          const response=  await axios.post('https://carrageyonetim.com/api/getBayiDukkanList', body, { headers })
           
             console.log(response.data);
             if (response) {
               const veriler = response.data.map(item => ({
                 Id: item.Id,
                 Name: item.Name,
                 Email: item.Email,
                 il:item.il,
                 ilce:item.ilce,
                 adres:item.adres,
                 dukkansahibi: item.dukkansahibi,
                 Table: item.Table
               }));
               setShops(veriler);
             }         
        }
      }
      catch (error) {
        console.log(error);
        navigate("/user/login");
      }
    };

    getToken();
  }, [navigate]);

  if (isLoading) {
    return (
      <>
      <Navbar />
    
      <div  style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          fontSize: 28,
          fontWeight: 'bold',
        }}>Loading...</div>
   
      <Footer />
      </>
    );
  }

 

  return (
    <>
      <Navbar />
      <br /><br /><br /><br /><br /><br />
      <div className='main'>
       <b style={{fontSize:24}}>BAYİLERİM</b> 
        <br/><br/>
        <hr></hr>
        
        <ul className='dukkanlistesi'>
        {shops.length === 0 ? (
          <p>Bayi bulunmamaktadır.</p>
        ) : (
          <ul className='dukkanlistesi'>
            {shops.map(shop => (
              <li className='dukkanlarim' key={shop.Id}>
                <Link to={`/user/UserHome/ShopDetails/${shop.Email}?table=${shop.Table}&shopID=${shop.Id}`} >{shop.Name}</Link>

              </li>
            ))}
          </ul>
        )}
        </ul>
      </div>
      <br /><br /><br /><br /><br /><br /><br />
      <Footer/>
    </>
  )
}

export default MyShops;
