import React, { useState,useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import "./userlist.css"
import Navbar from '../Components/header';
import ConfirmationPopup from '../Components/confirmation';
import axios from "axios";
import { useNavigate, Link, useHistory} from "react-router-dom";
import * as ReactRouterDOM from 'react-router-dom';
import Cookies from 'js-cookie';

function UrunList() {
  const products = [
    {
      id: 1,
      image: 'path_to_product_image1.jpg',
      name: 'Ürün Adı 1',
      shop: 'Dükkan Adı 1',
    },
    {
      id: 2,
      image: 'path_to_product_image2.jpg',
      name: 'Ürün Adı 2',
      shop: 'Dükkan Adı 2',
    },
    // Daha fazla ürün eklemek için bu şekilde devam edebilirsiniz
  ];

  const handleApprove = (productId) => {
    // Onaylama işlemini gerçekleştirmek için gerekli kodları burada yazabilirsiniz
    console.log(`Ürün ${productId} onaylandı`);
  };

  const handleReject = (productId) => {
    // Reddetme işlemini gerçekleştirmek için gerekli kodları burada yazabilirsiniz
    console.log(`Ürün ${productId} reddedildi`);
  };
      return (
        <>
      <Navbar/> 
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
       <div className="p-6">
          <table className="table-auto border-collapse w-full">
            <thead>
              <tr>
                <th className="border px-4 py-2">Ürün Fotoğrafı</th>
                <th className="border px-4 py-2">Ürün Adı</th>
                <th className="border px-4 py-2">Ürünü Ekleyen Dükkan</th>
                <th className="border px-4 py-2">İşlem</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id} className="border">
                  <td className="border px-4 py-2">
                    <img
                      src={product.image}
                      alt="Ürün Fotoğrafı"
                      className="w-16 h-16 object-cover"
                    />
                  </td>
                  <td className="border px-4 py-2">{product.name}</td>
                  <td className="border px-4 py-2">{product.shop}</td>
                  <td className="border px-4 py-2">
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleApprove(product.id)}
                    >
                      Onayla
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleReject(product.id)}
                    >
                      Reddet
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
      );
    };
export default UrunList
