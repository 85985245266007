import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Navbar from '../components/Navbar';
import './Bayi.css';
import axios from 'axios';
import Footer from '../components/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateNewShop() {
  const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z';
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    const getToken = () => {
      try {
        const token = Cookies.get('Usertoken');
        const decodedToken = jwtDecode(token, SECRET_KEY);
        const userID = localStorage.getItem("userID")
        console.log(userID)
        console.log(decodedToken);
        if (!decodedToken) {
          navigate('/user/login');
        } 
        
      } catch (error) {
        console.log(error);
        navigate('/user/login');
      }
    };

    getToken();
  }, [navigate]);

  const [formData, setFormData] = useState({
    name: '',
    city: '',
    district: '',
    address: '',
    ownerName: '',
    phoneNumber: '',
    email: '',
    password: '',
    selectedOption: 'OtoYikama',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const headers = {
    Authorization: '08x4IVCjdYc5ildyqBsFppOge2hNb6ms',
  };
  const body = {
    UserID: localStorage.getItem("userID"),
    Name: formData.name,
    il: formData.city,
    ilce: formData.district,
    adres: formData.address,
    dukkansahibi: formData.ownerName,
    PhoneNumber: formData.phoneNumber,
    Email: formData.email,
    Password: formData.password,
    Table: formData.selectedOption
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://carrageyonetim.com/api/addShops', body, { headers });
      console.log(response);
      if (response) {

        console.log(response.data);
        if(response.data.status === true){
          toast.success('Bayi oluşturuldu!', {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/user/UserHome/myShops")
        }else{
          navigate("/user/UserHome/createNewShop")
          toast.error('Bayi oluşturulurken bir hata oluştu!', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        console.log(response);
      }
    } 
    catch (error) {
      console.log(error);
      toast.error('Bir hata oluştu! Lütfen tekrar deneyin.', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  if (isLoading) {
    return (
      <>
      <Navbar />
    
      <div  style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          fontSize: 28,
          fontWeight: 'bold',
        }}>Loading...</div>
   
      <Footer />
      </>
    );
  }


  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <div className='main'>
        <br />
        <br />
        <br />
        <br />
        <h1 style={{ fontSize: 36, fontWeight: 'bold' }}>BAYİ OLUŞTURMA EKRANI</h1>
        <br />
        <br />
        <form >
          <input
            required
            className='input-bayi'
            type='text'
            placeholder='İsim'
            name='name'
            value={formData.name}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            required
            className='input-bayi'
            type='text'
            placeholder='İl'
            name='city'
            value={formData.city}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            required
            className='input-bayi'
            type='text'
            placeholder='İlçe'
            name='district'
            value={formData.district}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            required
            className='input-bayi'
            type='text'
            placeholder='Adres'
            name='address'
            value={formData.address}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            required
            className='input-bayi'
            type='text'
            placeholder='Dükkan Sahibi İsmi'
            name='ownerName'
            value={formData.ownerName}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            required
            className='input-bayi'
            type='text'
            placeholder='Telefon Numarası'
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            required
            className='input-bayi'
            type='text'
            placeholder='Email'
            name='email'
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <br />
          <input
            required
            className='input-bayi'
            type='password'
            placeholder='Password'
            name='password'
            value={formData.password}
            onChange={handleChange}
          />
          <br />
          <br />
          <select

            required
            style={{ border: '1px solid black' , width:'100%'}}
            name='selectedOption'
            value={formData.selectedOption}
            onChange={handleChange}
          >
            <option>OtoYikama</option>
            <option>LastikOteli</option>
            <option>OtoBakİmVeTamir</option>
            
          </select>
          <br />
          <br />
          <button onClick={handleSubmit} className='btn' style={{ fontWeight: 'bold', width: '200px' }}>
            OLUŞTUR
          </button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </form>
      </div>
      <br /><br /><br /><br /><br /><br /><br />
      <Footer/>
    </>
  );
}

export default CreateNewShop;
