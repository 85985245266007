import React, { useState,useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import "./userlist.css"
import Navbar from '../Components/header';
import ConfirmationPopup from '../Components/confirmation';
import axios from "axios";
import { useNavigate, Link, useHistory} from "react-router-dom";
import * as ReactRouterDOM from 'react-router-dom';
import Cookies from 'js-cookie';

const AracEkle = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('excelFile', selectedFile);
        const response = await axios.post('http://185.241.138.173:9988/api/addVehicles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': '08x4IVCjdYc5ildyqBsFppOge2hNb6ms'
          },
        });
        if(response.data.status){
          console.log('Dosya yükleme başarılı');
        }
        else{
          console.log('Dosya yükleme başarısız')
        }
      } catch (error) {
        console.error('Dosya yükleme hatası:', error);
      }
    } else {
      console.log('Dosya seçilmedi.');
    }
  };

  return (
    <>
    <Navbar/> 
    <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      <br>
      </br>
      
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Araç Ekle</h2>
      <form>
        <div className="mb-4">
          <label htmlFor="fileInput" className="block text-gray-700 font-bold mb-2">
            Excel Dosyası Seçin:
          </label>
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            className="p-2 border rounded"
          />
        </div>
        <button
          type="button"
          onClick={handleFileUpload}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Yükle
        </button>
      </form>
    </div>
    </>
  );
};

export default AracEkle;