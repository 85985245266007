import React, {useState, useEffect} from 'react'
import './header.css'
import logo from '../assets/img/logo.png'
import {AiFillCloseCircle} from 'react-icons/ai'
import {TbGridDots} from 'react-icons/tb'
import {MdOutlineTravelExplore} from 'react-icons/md'
import {BiPowerOff} from "react-icons/bi"
import {FaRegUser} from "react-icons/fa"
import {useCookies} from 'react-cookie'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import Cookies from 'js-cookie';

const Navbar = () => {
  const navigate= useNavigate();
  const isLogged = true
  const [active, setActive]  = useState('navBar')
  const showNav = ()=>{
      setActive('navBar activeNavbar')
  }
  const removeNav = ()=>{
      setActive('navBar')
  }
  const logout = () => {
    Cookies.remove('Admintoken');
    navigate('/master/login')
  }
  return (
    <section className='navBarSection'>
       <header className="header flex">
        
          <div className="logoDiv">
            <Link to="/master/AdminHome" className="logo flex"><h1><img style={{width:'100px',height:'100px'}} src={logo}></img> </h1></Link>
          </div>

          <div className={active}>
            <ul onClick={removeNav} className="navLists flex">
              <li className="navItem">
                <Link to='/master/AdminHome' className="navLink">Kullanıcı Ekle</Link>
              </li> 
              <li className="navItem">
                <Link to='/master/AdminHome/AracEkle' className="navLink">Araç Ekle</Link>
              </li> 
              <li className="navItem">
                <Link to='/master/AdminHome/BayiList' className="navLink">Bayi Listesi </Link>
              </li>   
              <li className="navItem">
                <Link to='/master/AdminHome/UrunList' className="navLink">Eklenen Ürün Listesi </Link>
              </li> 
              <li className="navItem">
                <Link to='/master/AdminHome/economyList' className="navLink">Ödenecekler Listesi </Link>
              </li> 
              <li  className="navItem">
              <Link className="navLink"><FaRegUser className='icon'/> </Link>
              </li>      
              <li onClick={logout} className="navItem" >
                <>
                  <Link><BiPowerOff className="icon"  /></Link>
                </>
              </li>
              
            </ul>
              <div onClick={removeNav} className="closeNavbar">
                <AiFillCloseCircle className='icon'/>
              </div>
          </div>

          <div onClick={showNav} className="toggleNavbar">
            <TbGridDots className='icon'/>
          </div>
       </header>
    </section>
    
  )
}

export default Navbar