import React, {useState, useEffect} from 'react'
import {AiFillCloseCircle} from 'react-icons/ai'
import {TbGridDots} from 'react-icons/tb'
import {MdOutlineTravelExplore} from 'react-icons/md'
import {BiPowerOff} from "react-icons/bi"
import {FaRegUser} from "react-icons/fa"
import {useCookies} from 'react-cookie'
import axios from 'axios'
import CreateNewShop from '../Home/CreateNewShop'
import logo from "../assets/img/logo.png"
import { useNavigate, Link, useHistory} from "react-router-dom";
import Cookies from 'js-cookie';

const Navbar = () => {
  
    const navigate= useNavigate();
  const isLogged = true
  const [active, setActive]  = useState('navBar')
  const showNav = ()=>{
      setActive('navBar activeNavbar')
  }
  const removeNav = ()=>{
      setActive('navBar')
  }
  const logout = () => {
    Cookies.remove('Usertoken');
    navigate("/user/login");
    window.location.reload();
  }
  return (
    <section className='navBarSection'>
       <header className="header flex">
        
          <div className="logoDiv">
            <Link to="/user/UserHome" className="logo flex"><img style={{width:'80px', height:'80px'}} src={logo}/> <h1 style={{color:'black',fontSize:24,marginLeft:'10px',fontWeight:'bold'}}> BAYİ PANELİ</h1></Link>
          </div>

          <div className={active}>
            <ul onClick={removeNav} className="navLists flex">
              <li className="navItem">
                <Link to="/user/UserHome" className="navLink">Ana Sayfa</Link>
              </li> 
              {/* <li onClick={goToRoutes}  className="navItem">
               Routes
              </li> */}
              {/* <li className="navItem">
                <a href="/packages" className="navLink">Packages</a>
              </li> */}
              <li className="navItem">
                <Link to="/user/UserHome/createNewShop"  className="navLink">+Bayi Oluştur</Link>
              </li>
              <li className="navItem">
                <Link to="/user/UserHome/myShops"  className="navLink">Bayilerim</Link>
              </li>
             
             
              {/* <li className="navItem">
                <Link  className="navLink">Contact</Link>
              </li> */}
                 
              
              
              < li className="navItem" onClick={logout}>
                        <button><h2 style={{fontSize:16, fontWeight:'bold'}}>ÇIKIŞ YAP</h2></button>
              </li>
              
           

            </ul>
              <div onClick={removeNav} className="closeNavbar">
                <AiFillCloseCircle className='icon'/>
              </div>
          </div>

          <div onClick={showNav} className="toggleNavbar">
            <TbGridDots className='icon'/>
          </div>
       </header>
    </section>
  )
}

export default Navbar