import React, {useEffect,useState} from 'react'
import "./Tanitim.css"
import "./contact.css"
import Aos from 'aos'
import {AiFillCloseCircle} from 'react-icons/ai'
import {TbGridDots} from 'react-icons/tb'
import logo from "../src/Bayi/assets/img/logo.png"
import { Link } from 'react-router-dom'
function Contact() {
    const [showNotification, setShowNotification] = useState(false);
    useEffect(()=>{
        Aos.init({duration: 2000})
      }, []) 
      
    
    
      const [active, setActive]  = useState('navBar')
      const showNav = ()=>{
          setActive('navBar activeNavbar')
      }
      const removeNav = ()=>{
          setActive('navBar')
      }
      const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setShowNotification(true);
        try {
          const response = await fetch('https://carrageapp.com/api/contactSave', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
            
            
          });
          const clear = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: '',
          }
          setFormData(clear)
          if (response.ok) {
            console.log('Form submitted successfully!');
          } else {
            console.error('Error submitting form:', response.statusText);
          }
          setTimeout(() => {
            setShowNotification(false);
          }, 5000);
        } catch (error) {
          console.error('Error submitting form:', error.message);
        }
      };
  return (
    <>
    <section className='navBarSection'>
    <header className="header flex">
     
       <div className="logoDiv">
         <div className="logo flex"><img style={{width:'80px', height:'80px'}} src={logo}/> <h1 style={{color:'black',fontSize:24,marginLeft:'10px',fontWeight:'bold'}}> Carrage </h1></div>
       </div>

       <div className={active}>
         <ul onClick={removeNav} className="navLists flex">
         
           <li className="navItem">
             <Link to="/"  className="navLink">Ana Sayfa</Link>
           </li>
         </ul>
           <div onClick={removeNav} className="closeNavbar">
             <AiFillCloseCircle className='icon'/>
           </div>
       </div>

       <div onClick={showNav} className="toggleNavbar">
         <TbGridDots className='icon'/>
       </div>
    </header>
    <div className="app">
      {/* Bildirimi göster */}
      {showNotification && (
           <div className="notification">
           <p>Form başarıyla gönderildi!</p>
           <button onClick={() => setShowNotification(false)}>Kapat</button>
         </div>
      )}
    </div>
 </section>
    <div  data-aos="fade-up" className="privacy-page">
    <h1 className="privacy-heading"> Carrage İletişim</h1>
    <div className="privacy-content">
    <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-4">İletişim Formu</h2>
      {/* Bildirimi göster */}
      {showNotification && (
           <div className="mb-4">
           <label className="block text-gray-700 text-sm font-bold mb-2">
             Form Başarı İle Gönderildi ✔️
           </label>
         </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Ad:
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="form-input mt-1 block w-full"
              required
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Soyad:
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="form-input mt-1 block w-full"
              required
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            E-posta:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-input mt-1 block w-full"
              required
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Telefon Numarası:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="form-input mt-1 block w-full"
              required
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Soru Hakkında Bilgiler:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="form-textarea mt-1 block w-full"
              required
            />
          </label>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
        >
          Gönder
        </button>
      </form>
    </div>
      
    <div id='iletisim' className='subscribe  section'>
        <div data-aos="fade-up" data-aos-duration="2500" className="sectionContainer container">
            <h2>İLETİŞİM</h2>
           
               <div className='contact'>
                    <div className='contact-info'>
                    <h3>Adres:</h3><p>
            Aşağı Öveçler Cad, 1322. Cd. No:75/4 
            </p><br/>
          <h3>Telefon Numarası: +90 538 928 4605</h3>
          <h3>Email: info@carrageapp.com</h3>
        </div>
      
      </div>

        </div>
    </div>
    </div>
  </div>
  </>
  )
}

export default Contact
