import React , { useState, useEffect} from 'react';
import { Link } from 'react-router-dom'; // Eğer sayfalar arası yönlendirme için React Router kullanıyorsanız
import "./Tanitim.css"
import {AiFillCloseCircle} from 'react-icons/ai'
import {TbGridDots} from 'react-icons/tb'
import logo from "../src/Bayi/assets/img/logo.png"
import banner from "../src/Bayi/assets/img/Carragebanner.png"
import valet from "../src/Bayi/assets/img/valet.png"
import './Tanitim.css'
import 'aos/dist/aos.css'
import Aos from 'aos'
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import { scroller } from 'react-scroll';
const Tanitim = () => {
  useEffect(()=>{
    Aos.init({duration: 2000})
  }, []) 

  
  const [active, setActive]  = useState('navBar')
  const showNav = ()=>{
      setActive('navBar activeNavbar')
  }
  const removeNav = ()=>{
      setActive('navBar')
  }
  const [transparent, setTransparent] = useState('navBarTwo');
  const addBg = () => {
    if (window.scrollY >= 10) {
      setTransparent('navBarTwo activeHeader');
    } else {
      setTransparent('navBarTwo');
    }
  };
  window.addEventListener('scroll', addBg);

  const handleScroll = (target) => {
    removeNav();
    scroller.scrollTo(target, {
      duration: 900,
      smooth: true,
    });
  };


  return (
    <>
    
    <div className='ttanitim'>
    <section className='navBarSection'>
    <header className="header flex">
     
       <div className="logoDiv">
         <div className="logo flex"><img style={{width:'80px', height:'80px'}} src={logo}/> <h1 style={{color:'black',fontSize:24,marginLeft:'10px',fontWeight:'bold'}}> Carrage </h1></div>
       </div>

       <div className={active}>
         <ul onClick={removeNav} className="navLists flex">
           <li onClick={() => handleScroll('hakkimizda')} className="navItem">
             <div className="navLink">Hakkımızda</div>
           </li> 
          
           <li className="navItem">
            <Link to="/contact"  className="navLink">İletişim</Link>
           </li>
           <li className="navItem">
             <Link to="/kvkk"  className="navLink">Gizlilik Politikası</Link>
           </li>
          
          
          
           
           
          
        

         </ul>
           <div onClick={removeNav} className="closeNavbar">
             <AiFillCloseCircle className='icon'/>
           </div>
       </div>

       <div onClick={showNav} className="toggleNavbar">
         <TbGridDots className='icon'/>
       </div>
    </header>
 </section>

<div className='home flex container'>
<div className="mainText">
    <h1>Carrage © 2023</h1>
</div> 
<div  data-aos="fade-left" className="homeImages flex">
  <div className="videoDiv">
  <img src={banner} autoPlay muted loop className='video'></img>
  </div>
 
  
</div>
</div>
<div  data-aos="fade-up" className="app-buttons">
          <div className="app-button">
          <FaGooglePlay /> Play Store'dan İndir
          </div>
          <div href="link-to-appstore" className="app-button">
          <FaApple /> App Store'dan İndir
          </div>
        </div>
<div id='hakkimizda' className='lounge container section'>
         <div className="sectionContainer grid">
           <div data-aos="fade-left" data-aos-duration="2500" className="imgDiv">
             <img src={logo} alt="" />
           </div>
           <div className="textDiv">
            <h2 data-aos="fade-down" data-aos-duration="2500">HAKKIMIZDA</h2>

            <div className="grids ">
                <div data-aos="fade-down" data-aos-duration="2500" className="singleGrid">
                    <span className="gridTitle">
                    Carrage
                    </span>
                    <p>
                    Yeni nesil araç bakım istasyonunuz Carrage'a Hoşgeldiniz. 
                    Modern yaşamın ihtiyaçları gereği, araç bakımınızı tüm koşuşturmalarınızın arasında aklınızdan çıkaracak şekilde yeniden tasarladık. Mükemmellik taahhüdümüzle, aracınız için ihtiyacınız olan tüm hizmetleri oto yıkama kuyruklarında beklemeden, bakım prosedürleriyle uğraşmadan ve buna benzer nice zahmetten kurtararak kapınıza getirelim. Profesyonellerden oluşan ekibimiz, aracınızın tüm bakımını yaparken, isteğiniz doğrultusunda diğer tüm detaylı hizmetleri de beraberinde sunmaktadır.  
                    Size gerçekten hak ettiğiniz araç bakım deneyimini sunabilmemiz için kalite, rahatlık ve kişiselleştirilmiş bakımın kusursuz bir şekilde birleştiği yeni nesil araç bakım ailemize katılın.

                    Carrage… Siz işinize bakın, biz arabanıza…
                    </p>
                </div>

              
            </div>
           </div>
         </div>
    </div>


    <div className='support container section'>
         <div className="sectionContainer">
            <div  className="titlesDiv">
                <small>Carrage Destek</small>
                <h2>Nasıl Çalışıyoruz?</h2>
            </div>

            <div className="infoDiv grid">
                <div className="textDiv grid">
                    <div data-aos="fade-down" data-aos-duration="2500" className="singleInfo">
                        <span className="number">01</span>
                        <h4>Müşteri İstekleri Belirleme:</h4>
                        <p>Müşteri ile iletişime geçiyor ve aracın ihtiyaçlarına odaklanan bir ön inceleme gerçekleştiriyoruz. Bu sayede, müşterilerin isteklerini ve araçlarının spesifik ihtiyaçlarını anlamış oluyoruz. </p>
                    </div>

                    <div data-aos="fade-down" data-aos-duration="3500" className="singleInfo">
                        <span className="number colorOne">02</span>
                        <h4>Hızlı ve Etkili Hizmet:</h4>
                        <p>Uzman ekibimiz, müşteri onayı sonrasında araç bakımını hızlı ve etkili bir şekilde gerçekleştirir. Carrage olarak, müşterilerimizin zamanını değerli buluyor ve işimizi en kısa sürede tamamlamak için çaba sarf ediyoruz. </p>
                    </div>

                    <div data-aos="fade-down" data-aos-duration="4500" className="singleInfo">
                        <span className="number colorTwo">03</span>
                        <h4> İhtiyaçları Karşılamak İçin Çalışma:</h4>
                        <p>Müşterinin taleplerine odaklanarak, araç bakımının yanı sıra ek hizmetleri de sunuyoruz. Müşterilerimizin beklentilerini aşmak için çaba gösteriyor ve kaliteli hizmet anlayışımızı her aşamada sürdürüyoruz. </p>
                    </div>

                </div>

                <div data-aos="fade-left" data-aos-duration="2500" className="imgDiv">
                    <img src={valet} alt="" />
                </div>

            </div>
         </div>
    </div>

    <div id='iletisim' className='subscribe  section'>
        <div data-aos="fade-up" data-aos-duration="2500" className="sectionContainer container">
            <h2>İLETİŞİM</h2>
           
               <div className='contact'>
        <div className='contact-info'>
          <h3>Adres:</h3><p>
Aşağı Öveçler Cad, 1322. Cd. No:75/4 
</p><br/>
          <h3>Telefon Numarası: +90 (530) 928 4605</h3>
          <h3>Email: info@carrageapp.com</h3>
        </div>
      
      </div>

        </div>
    </div>
</div>
</>
  );
}

export default Tanitim;
