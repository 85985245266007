import React from 'react';

const ConfirmationPopup = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6">
        <p className="text-lg mb-4">Kullanıcı Başarı İle Eklendi ✅.</p>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={onConfirm}
          >
            Onayla
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
