import React, { useState,useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import "./userlist.css"
import Navbar from '../Components/header';
import ConfirmationPopup from '../Components/confirmation';
import axios from "axios";
import { useNavigate, Link, useHistory} from "react-router-dom";
import * as ReactRouterDOM from 'react-router-dom';
import Cookies from 'js-cookie';

function UserList() {
    const [Username, setUsername] = useState('')
    const [Password, setPassword] = useState('')
    const [Company_Name, setBayi] = useState('')
    const [showPopup, setShowPopup] = useState(false);
    const [users, setUsers] = useState([
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 3, name: 'Bob Johnson' }
      ]);
    
    const SECRET_KEY = 'lN$7#&*X^Jd6U+!vYe!f+K~Y15_%z';
    const headers = {
      'Authorization': '08x4IVCjdYc5ildyqBsFppOge2hNb6ms'
    };
    const navigate= useNavigate();
    useEffect( () => {
        const UserList = async () => {
            const response = await axios.post('http://185.241.138.173:9988/api/getBayiList',Username,{headers});
            console.log(response.data)
            setUsers(response.data)
        }
        const getToken =  () => {
          try{
            const token = Cookies.get('Admintoken');
            const decodedToken = jwtDecode(token, SECRET_KEY);
            console.log(decodedToken)
            if (!decodedToken) {
             navigate("/master/login")
            }
          }
          catch(error){
            console.log(error)
            navigate("/master/login")
          }
    
        }
        UserList()
        getToken();
      }, []);
    const userDelete = async (id) => {
        console.log(id)
      }
  return (
    <>
    <Navbar/>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div >
        <table className="min-w-full divide-y divide-gray-200">
        <thead>
            <tr>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-black-500 uppercase tracking-wider">ID</th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-black-500 uppercase tracking-wider">Kullanıcı Adı</th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-black-500 uppercase tracking-wider">Şirket Adı</th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-black-500 uppercase tracking-wider"></th>
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
            {users.map(user => (
            <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">{user.Id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">{user.Username}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">{user.Company_Name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button onClick={() => userDelete(user.Id)} className="text-red-600 hover:text-red-900">Sil</button>

                </td>
            </tr>
            ))}
        </tbody>
        </table>
        </div>
    </>
  );
}

export default UserList
